import { Injectable, Inject, Optional } from '@angular/core';
import {
  HttpHeaders,
  HttpClient,
  HttpResponseBase,
  HttpResponse,
  HttpParams,
} from '@angular/common/http';
import {
  Observable,
  BehaviorSubject,
  ReplaySubject,
  throwError as _observableThrow,
  of as _observableOf,
} from 'rxjs';
import {
  map,
  mergeMap as _observableMergeMap,
  catchError as _observableCatch,
  skip,
} from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { PlatformService } from '@common/co/core/services/platform.service';
import { AngularRequestor } from '../utils/angular-requestor';
import {
  API_BASE_URL,
  RegisterCommand,
  IRegisterResponse,
  AuthClient,
  IRegisterCommand,
  CreateAnotherAthleteCommand,
  ProfileClient,
  AthleteDto,
  RemoveAccountCommand,
  RemoveAccountResponse,
  RegisterResponse,
  CreateAnotherUniqieIdCommand,
  OidcAuthErrorConstants,
} from '@common/services/co-api-client';
import { TenantEntity } from '@common/co/auth/model/tenant-entity.model';
import * as _ from 'lodash';
import { IAccessToken } from '@common/co/auth/model/access-token';
import { UserEntity } from '@common/co/auth/model/user-entity.model';
import { AppBusService } from '@common/co/core/services/app-bus.service';
import { StorageService } from '@common/services/storage.service';
import { Router } from '@angular/router';
import { OAuth2AuthenticateOptions } from '@common/co/core/models/OAuth2AuthenticateOptions';
import {
  APPLICATIONS_PATHS,
  IApplicationPathsType,
} from '@common/co/core/app.constants';
import {
  INavigationConfig,
  NAVIGATION_CONFIG,
} from '@common/co/navigation/navigation';
import { applicationStorageFields } from '@common/co/core/app-storage.constants';
import { APP_CONFIG, IAppConfig } from '@common/co/core/config/app.config';
import { getSplNotification } from '@common/co/core/helpers/spl-notification';
import { LoggingService } from '@common/services/logging.service';
import { ActualizeProfileInfoModel } from '@common/co/feature/in-take/models/actualize-profile-Info.model';
import { IAuthService } from '@common/services/iauth-service';
import { DateTimeService } from '@common/services/date-time.service';

@Injectable()
export class AuthorizeService implements IAuthService {
  private baseUrl: string;
  private http: HttpClient;

  inTakePassedSubscription = null;

  public get currentTenantId(): string {
    return this.currentTenant?.TenantId;
  }
  public currentTenant: TenantEntity;
  public availableTenants: TenantEntity[];
  public singleTenant: boolean;
  public get currentProfileId(): string {
    return this._currentProfile?.id;
  }

  public async setCurrentProfileId(value: string): Promise<void> {
    for (const profile of this.availableProfiles) {
      if (profile.id == value) {
        await this.setCurrentProfile(profile).then((r) => r);
        break;
      }
    }
  }
  public get currentProfile(): AthleteDto {
    return this._currentProfile;
  }

  public get IsUserRegistrationNotCompleted(): boolean {
    return this.userRegistrationNotCompleted;
  }
  private _currentProfile: AthleteDto;
  public availableProfiles: AthleteDto[];
  public user$: Observable<UserEntity>;
  public initialized$: Observable<boolean>;
  private userSubject = new BehaviorSubject<UserEntity>(null);
  private initializedSubject = new ReplaySubject<boolean>();
  private refsreshTokenForcedPromise: Promise<{
    access_token: string;
    refresh_token: string;
  }> = null;
  private userRegistrationNotCompleted: boolean;

  public idToken: string;
  private accessToken: string;
  private refreshToken: string;
  public expiresIn: number;
  public issuedAt: number;

  private jwtHelperService: JwtHelperService;

  public oidcConfig: OAuth2AuthenticateOptions = undefined;
  constructor(
    @Inject(NAVIGATION_CONFIG) private navigationConfig: INavigationConfig,
    @Inject(APPLICATIONS_PATHS) private applicationPaths: IApplicationPathsType,
    @Inject(APP_CONFIG) private appConfig: IAppConfig,
    private platformService: PlatformService,
    private angularRequestor: AngularRequestor,
    private _authClient: AuthClient,
    private _appBusService: AppBusService,
    private _router: Router,
    private _storage: StorageService,
    private profileClient: ProfileClient,
    private loggingService: LoggingService,
    private dateTimeService: DateTimeService,
    @Inject(HttpClient) http: HttpClient,
    @Optional() @Inject(API_BASE_URL) baseUrl?: string,
  ) {
    _appBusService.inTakePassed$.subscribe((value) =>
      this.setInTakePassedFlag(value),
    );
    this.user$ = this.userSubject.asObservable();
    this.initialized$ = this.initializedSubject.asObservable();
    this.jwtHelperService = new JwtHelperService();
    this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : '';
    this.http = http;
  }

  public async initialize(): Promise<boolean> {
    console.debug('AuthorizeService => setup => started');

    await this.getOidcConfigSafe();

    const serializedUserInfo = await this._storage.get({
      key: applicationStorageFields.STORAGE_USER_INFO,
    });
    const user: UserEntity = JSON.parse(serializedUserInfo.value);
    if (user) {
      const profiles = await this.getProfiles();
      if (profiles) this.tryToSetAvailableProfiles(user, profiles);
    }

    console.debug('AuthorizeService => setup => finished');
    this.initializedSubject.next(true);
    return Promise.resolve(true);
  }

  public async getOidcConfigSafe(): Promise<OAuth2AuthenticateOptions> {
    await this.repopulateFromStorage();

    if (!this.oidcConfig) {
      this.oidcConfig = await this.platformService.getOidcConfiguration();
      if (!this.oidcConfig) return;
    }

    const optionsSerialized = JSON.stringify(this.oidcConfig);
    await this._storage.set({
      key: applicationStorageFields.STORAGE_OIDC_CONFIG,
      value: optionsSerialized,
    });

    return this.oidcConfig;
  }

  public isAuthenticated(): Observable<boolean> {
    return this.user$.pipe(map((u) => !!u));
  }

  public async isAuthorizationExpired(): Promise<boolean> {
    const token = await this.getAccessToken();
    return !token.accessToken;
  }

  public async getAccessToken(): Promise<IAccessToken> {
    const now = Math.round(new Date().getTime() / 1000) + 30; //need offset 30s to avoid expire at last moment
    await this.ensureTokensActual();
    if (this.accessToken) {
      const expirationDate = await this.jwtHelperService.getTokenExpirationDate(
        this.accessToken,
      );

      const expTime = Math.round(expirationDate.getTime() / 1000);
      if (expTime >= now) {
        console.debug('AuthorizeService => isAccessTokenExpired => false');
        return Promise.resolve({
          accessToken: this.accessToken,
          status: 0,
        });
      }
    }

    if (
      !this.refsreshTokenForcedPromise &&
      (!this.refreshToken ||
        this.refreshToken.trim() === 'undefined' ||
        this.refreshToken.trim() === '')
    ) {
      console.debug('AuthorizeService => isAccessTokenExpired => true');
      return Promise.resolve({
        accessToken: null,
        status: 0,
      });
    }
    const token = await this.refreshTokenForced();
    return Promise.resolve(token);
  }

  public async createAnother(): Promise<void> {
    await this.createProfile(this.createAnotherApi.bind(this));
  }

  public async createAnotherUniqueId(
    uniqueId?: string,
    dateOfBirth?: Date,
  ): Promise<void> {
    await this.createProfile(
      this.createAnotherUniqueIdApi.bind(this),
      uniqueId,
      this.dateTimeService.getDateWithoutTimeZone(dateOfBirth),
    );
  }

  private async createProfile(
    apiMethod: any,
    uniqueId?: string,
    dateOfBirth?: Date,
  ): Promise<void> {
    let name: string;
    if (this.availableProfiles.length > 0) {
      name = `${this.availableProfiles[0].name}_${this.availableProfiles.length}`;
    } else {
      const userName = new Promise((resolve) => {
        this._appBusService.loginData$.subscribe((userInfo) => {
          resolve(userInfo.userName);
        });
      });
      name = `${await userName}_${this.availableProfiles.length}`;
    }
    const newProfile = await apiMethod(name, uniqueId, dateOfBirth);
    if (newProfile) {
      await this.refreshTokenForced();

      this.availableProfiles.push(newProfile);
      if (
        !this.availableTenants.find((t) => t.TenantId == newProfile.tenant.name)
      ) {
        this.availableTenants.push({
          TenantId: newProfile.tenant.name,
          TenantName: newProfile.tenant.name,
        });
      }
      this.singleTenant = this.checkProfilesInSameTenant(
        this.availableProfiles,
      );

      await this.clearSelectedProfileInfo();
      await this.setCurrentProfileId(newProfile.id);
      this.inTakePassedSubscription = this._appBusService.inTakePassed$
        .pipe(skip(1))
        .subscribe(() => {
          this._router.navigateByUrl(this.navigationConfig.root.url, {
            replaceUrl: true,
          });
          this.inTakePassedSubscription.unsubscribe();
        });
    }
    return Promise.resolve();
  }

  async createAnotherApi(
    name: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    uniqueId?: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    dateOfBirth?: Date,
  ): Promise<AthleteDto> {
    const athleteDto = await this._authClient
      .createAnother(
        new CreateAnotherAthleteCommand({
          name: name,
        }),
      )
      .toPromise();
    if (athleteDto) {
      return athleteDto;
    }
  }

  async createAnotherUniqueIdApi(
    name: string,
    uniqueId: string,
    dateOfBirth: Date,
  ): Promise<AthleteDto> {
    const createAnotherUniqueIdResponse = await this._authClient
      .createAnotherUniqueId(
        new CreateAnotherUniqieIdCommand({
          name: name,
          uniqueId,
          dateOfBirth,
        }),
      )
      .toPromise();
    if (createAnotherUniqueIdResponse.success) {
      return createAnotherUniqueIdResponse.personalRecord;
    } else {
      this._appBusService.processNotification(
        getSplNotification('error', createAnotherUniqueIdResponse?.reason),
      );
    }
  }

  private async clearSelectedProfileInfo(): Promise<void> {
    this._appBusService.inTakePassed(false);
    const serializedUserInfo = await this._storage.get({
      key: applicationStorageFields.STORAGE_USER_INFO,
    });
    const userObject = JSON.parse(serializedUserInfo.value);
    delete userObject.isInTakePassed;

    await this._storage.set({
      key: applicationStorageFields.STORAGE_USER_INFO,
      value: JSON.stringify(userObject),
    });
  }

  public async registerUser(data: IRegisterCommand): Promise<RegisterResponse> {
    const response = await this._authClient
      .register(new RegisterCommand({ ...data }))
      .toPromise();
    if ((response as IRegisterResponse).success) {
      if (this.IsUserRegistrationNotCompleted) {
        this.userRegistrationNotCompleted = false;
        await this._storage.set({
          key: applicationStorageFields.STORAGE_USER_NOT_COMPLETED,
          value: `${this.userRegistrationNotCompleted}`,
        });
      } else {
        await this.authenticateUser(data.email, data.password);
      }
    }

    return Promise.resolve(response);
  }

  public async deleteAccount(): Promise<void> {
    try {
      const response = await this._authClient
        .removeAccount(new RemoveAccountCommand())
        .toPromise();
      if ((response as RemoveAccountResponse)?.success) {
        this.logout();
      }
    } catch (reason) {
      const authException = this.parseTokenReponseException(reason);
      if (AuthException.isAuthException(authException)) throw authException;
      throw reason;
    }
  }

  public async externalAuthenticateUser(
    token: string,
    refreshToken: string,
    provider: string,
  ): Promise<void> {
    console.debug('authenticateUser => started');
    try {
      this.accessToken = token;
      this.refreshToken = refreshToken;
      this.validateAccessToken(this.accessToken);
      await this._storage.set({
        key: applicationStorageFields.STORAGE_ACCESS_TOKEN,
        value: provider,
      });
      await this._storage.set({
        key: applicationStorageFields.STORAGE_ACCESS_TOKEN,
        value: this.accessToken,
      });
      await this._storage.set({
        key: applicationStorageFields.STORAGE_LOGGED_BY_PROVIDER,
        value: provider,
      });
      const userInfo = await this.fetchUserInfo();
      await this.setAvailableProfiles(userInfo);
      await this.setUserSubject(userInfo);
    } catch (reason) {
      await this.setUserSubject(null);
      const authException = this.parseTokenReponseException(reason);
      if (AuthException.isAuthException(authException)) throw authException;
      throw reason;
    }
  }

  public async authenticateUser(
    userName: string,
    password: string,
  ): Promise<void> {
    console.debug('authenticateUser => started');
    await this._storage.clear();
    const oidcConfig = await this.getOidcConfigSafe();
    try {
      const response = await this.requestAccessToken(
        userName,
        password,
        oidcConfig,
      ).toPromise();
      this.accessToken = response['access_token'];
      this.refreshToken = response['refresh_token'];

      this.validateAccessToken(this.accessToken);

      await this._storage.set({
        key: applicationStorageFields.STORAGE_ACCESS_TOKEN,
        value: this.accessToken,
      });

      await this._storage.set({
        key: applicationStorageFields.STORAGE_REFRESH_TOKEN,
        value: this.refreshToken,
      });

      const userInfo = await this.fetchUserInfo();
      await this.setAvailableProfiles(userInfo);
      await this.setUserSubject(userInfo);
      this._appBusService.login();
    } catch (reason) {
      await this.setUserSubject(null);
      const authException = this.parseTokenReponseException(reason);

      if (AuthException.isAuthException(authException)) throw authException;

      throw reason;
    }
  }

  async setAvailableProfiles(
    userInfo?: UserEntity,
    availableProfiles?: AthleteDto[],
  ): Promise<void> {
    this.tryToSetAvailableProfiles(
      userInfo ?? (await this.fetchUserInfo()),
      availableProfiles ?? (await this.getProfiles()),
    );
  }

  async setInTakePassedFlag(value: boolean): Promise<void> {
    if (value !== null) {
      const serializedUserInfo = await this._storage.get({
        key: applicationStorageFields.STORAGE_USER_INFO,
      });
      if (serializedUserInfo.value) {
        const userObject = JSON.parse(serializedUserInfo.value);

        const userEntity: UserEntity = this.mapUserEntity(userObject);
        if (userEntity.isInTakePassed != value) {
          userEntity.isInTakePassed = value;

          await this.setUserSubject(userEntity);
        }
      }
    }

    return Promise.resolve();
  }

  private async setUserSubject(userEntity: UserEntity): Promise<void> {
    console.log('setUserSubject');
    this.userSubject.next(userEntity);
    if (!userEntity) {
      return;
    }
    await this._storage.set({
      key: applicationStorageFields.STORAGE_USER_INFO,
      value: JSON.stringify(userEntity),
    });
    this._appBusService.loginData({
      userId: userEntity.sub,
      userName: userEntity.name,
      currentTenantId: this.currentTenantId,
      tenants: userEntity.tenants,
      profile: userEntity.currentProfileId,
    });
  }

  async setCurrentProfile(profile: AthleteDto): Promise<void> {
    console.log('try to set current profile', profile);
    this._currentProfile = profile;
    const tenantId = this._currentProfile.tenant?.name;
    this.tryToSetTenant(tenantId);
    this.loggingService.logTrace(`Set Current Profile in AuthorizeService`, {
      availableProfiles: JSON.stringify(this.availableProfiles),
      currentProfile: JSON.stringify(this._currentProfile),
    });
    const serializedUserInfo = await this._storage.get({
      key: applicationStorageFields.STORAGE_USER_INFO,
    });

    if (serializedUserInfo.value) {
      const userObject = JSON.parse(serializedUserInfo.value);

      const userEntity: UserEntity = this.mapUserEntity(userObject);
      userEntity.currentProfileId = profile.id;
      userEntity.tenants = JSON.stringify(this.availableTenants);
      userEntity.isInTakePassed = undefined;
      await this.setUserSubject(userEntity);

      await this._storage.set({
        key: applicationStorageFields.STORAGE_USER_INFO,
        value: JSON.stringify(userEntity),
      });
    }

    this._appBusService.setProfile(profile);

    return Promise.resolve();
  }

  private async ensureTokensActual(): Promise<any> {
    const accessToken = await this._storage.get({
      key: applicationStorageFields.STORAGE_ACCESS_TOKEN,
    });
    if (accessToken) {
      this.accessToken = accessToken.value;
    } else {
      this.accessToken = undefined;
    }
    const refreshToken = await this._storage.get({
      key: applicationStorageFields.STORAGE_REFRESH_TOKEN,
    });
    if (refreshToken) {
      this.refreshToken = refreshToken.value;
    } else {
      this.refreshToken = undefined;
    }
  }

  private parseTokenReponseException(exception: any): AuthException {
    if (!exception) return null;

    const responseBody = exception.response
      ? JSON.parse(exception.response)
      : { error: 'unknown', error_description: 'unknown' };

    return new AuthException(
      this.getExceptionMessageByErrorDescription(
        responseBody.error,
        responseBody.error_description,
      ),
      exception.status,
      responseBody.error,
      responseBody.error_description,
      exception.headers,
    );
  }

  private tryToSetTenant(tenantId: string): void {
    if (this.availableTenants && this.availableTenants.length > 0) {
      const tenant = this.availableTenants.find((t) => t.TenantId == tenantId);
      this.currentTenant = tenant;
      console.log('set tenant:', this.currentTenant);
    }
  }

  private tryToSetAvailableProfiles(
    userInfo: UserEntity,
    profiles: AthleteDto[],
  ): void {
    if (userInfo) {
      this.availableProfiles = profiles;
      this.singleTenant = this.checkProfilesInSameTenant(
        this.availableProfiles,
      );
      const count: number = this.availableProfiles?.length || 0;
      if (count > 1) {
        if (userInfo.currentProfileId) {
          const userProfile = this.availableProfiles.find(
            (p) => p.id == userInfo.currentProfileId,
          );
          this._currentProfile = userProfile;
        }
      } else if (count == 1) {
        this._currentProfile = this.availableProfiles[0];
      } else {
        console.log('use select-profile from storage', this.currentProfile);
      }

      this.availableTenants = this.parseAvailableTenants(userInfo.tenants);

      if (this._currentProfile && !!this._currentProfile.tenant) {
        userInfo.currentProfileId = this._currentProfile.id;
        this.tryToSetTenant(this._currentProfile.tenant.name);
      } else {
        const firstTenant = this.availableTenants[0];
        if (firstTenant) {
          this.tryToSetTenant(firstTenant.TenantId);
        }
      }

      this.loggingService.logTrace(
        `Try To Set Available Profiles in AuthorizeService`,
        {
          availableProfiles: JSON.stringify(this.availableProfiles),
          availableProfilesCount: JSON.stringify(count),
          userInfo: JSON.stringify(userInfo),
          currentProfile: JSON.stringify(this._currentProfile),
        },
      );

      if (this._currentProfile) {
        this._appBusService.setProfile(this._currentProfile);
      }
    }
  }

  private checkProfilesInSameTenant(profiles: AthleteDto[]): boolean {
    if (profiles.length == 1) return true;

    let lastTenant: string;
    for (let i: number = 0; i < profiles.length; i++) {
      const p = profiles[i];
      if (p.tenant) {
        if (lastTenant && lastTenant != p.tenant.name) {
          return false;
        }
        lastTenant = p.tenant.name;
      }
    }
    return true;
  }

  public actualizeProfileInfo(info: ActualizeProfileInfoModel): void {
    const foundProfileIndex = this.availableProfiles.findIndex((profile) => {
      return profile.id === this.currentProfileId;
    });
    if (foundProfileIndex > -1) {
      if (info.profileName) {
        this.availableProfiles[foundProfileIndex].name = info.profileName;
      }
      if (info.gender) {
        this.availableProfiles[foundProfileIndex].gender = info.gender;
      }
      if (info.locale) {
        this.availableProfiles[foundProfileIndex].locale = info.locale;
      }
      if (info.inTake) {
        this.currentProfile.inTakes.push(info.inTake);
        this.availableProfiles[foundProfileIndex].inTakes =
          this.currentProfile.inTakes;
      }
      this._appBusService.actualizeProfileInfo$.next();
    }
  }

  private parseAvailableTenants(tenantsClaim: string | []): TenantEntity[] {
    let tenants;
    if (typeof tenantsClaim === 'string') {
      tenants = JSON.parse(tenantsClaim);
    } else if (
      Object.prototype.toString.call(tenantsClaim) === '[object Array]'
    ) {
      tenants = _.map(tenantsClaim, (t) => JSON.parse(t));
    }

    return _.map(tenants, (t) => new TenantEntity(t));
  }

  private parseAvailableProfiles(profileClaim: any): AthleteDto[] {
    let profiles;
    if (typeof profileClaim === 'string') {
      profiles = JSON.parse(profileClaim);
    } else if (
      Object.prototype.toString.call(profileClaim) === '[object Array]'
    ) {
      profiles = _.map(profileClaim, (p) => JSON.parse(p));
    }
    console.log(profiles);
    return _.map(profiles, (p) => {
      return AthleteDto.fromJS(p);
    });
  }

  private getExceptionMessageByErrorDescription(
    error: string,
    errorDescription: string,
  ): string {
    const oidcAuthErrorConstants = new OidcAuthErrorConstants();
    if (error == oidcAuthErrorConstants.accessDenied) {
      return errorDescription;
    }

    switch (errorDescription) {
      case 'invalid_username_or_password':
        return 'Username or password is not valid';
      default:
        return 'Authentication exception occured';
    }
  }

  // public async startAuthentication(): Promise<void> {
  //   console.debug('startAuth => started');
  //   try {
  //     const response = await OAuth2Client.authenticate(this.oidcConfig);
  //     this.accessToken = response['access_token'];
  //     this.refreshToken = response['refresh_token'];

  //     this.validateAccessToken(this.accessToken);

  //     await this._storage.set({
  //       key: this.STORAGE_ACCESS_TOKEN,
  //       value: this.accessToken,
  //     });

  //     await this._storage.set({
  //       key: this.STORAGE_REFRESH_TOKEN,
  //       value: this.refreshToken,
  //     });

  //     await this.fetchUserInfo();
  //   } catch (reason) {
  //     this.setUserSubject(null);
  //     console.error('OAuth rejected', reason);
  //   }
  // }

  public async refreshTokenForced(): Promise<IAccessToken> {
    console.debug('tokenRefresh => started');
    let promiseInitiator = false;
    try {
      if (!this.refsreshTokenForcedPromise) {
        if (!this.refreshToken) return null;
        promiseInitiator = true;
        this.refsreshTokenForcedPromise = this._storage
          .remove({
            key: applicationStorageFields.STORAGE_REFRESH_TOKEN,
          })
          .then(async () => {
            const refreshToken = this.refreshToken;
            this.refreshToken = undefined;
            const oidcConfig = await this.getOidcConfigSafe();
            return this.refreshAccessToken(
              refreshToken,
              oidcConfig,
            ).toPromise();
          });
      }
      const response = await this.refsreshTokenForcedPromise;
      const accessToken = response['access_token'];
      if (promiseInitiator) {
        this.accessToken = accessToken;
        this.refreshToken = response['refresh_token'];
        this.validateAccessToken(this.accessToken);
        await this._storage.set({
          key: applicationStorageFields.STORAGE_ACCESS_TOKEN,
          value: this.accessToken,
        });

        await this._storage.set({
          key: applicationStorageFields.STORAGE_REFRESH_TOKEN,
          value: this.refreshToken,
        });
      }
      this.refsreshTokenForcedPromise = null;
      return Promise.resolve({
        accessToken: accessToken,
        status: 200,
      });
    } catch (e) {
      this.refsreshTokenForcedPromise = null;
      console.error('OAuth refresh rejected', e);
      await this.setUserSubject(null);
      return Promise.resolve({
        accessToken: null,
        status: e.status,
      });
    }
  }

  // public async refreshTokenForcedWithRedirect(): Promise<boolean> {
  //   console.debug('tokenRefresh => started');
  //   try {
  //     const refreshTokenOptions: OAuth2RefreshTokenOptions =
  //       this.generateRefreshOptions(this.oidcConfig);
  //     const response = await OAuth2Client.refreshToken(refreshTokenOptions);
  //     this.accessToken = response['access_token'];
  //     this.refreshToken = response['refresh_token'];

  //     this.validateAccessToken(this.accessToken);

  //     await this._storage.set({
  //       key: this.STORAGE_ACCESS_TOKEN,
  //       value: this.accessToken,
  //     });

  //     await this._storage.set({
  //       key: this.STORAGE_REFRESH_TOKEN,
  //       value: this.refreshToken,
  //     });
  //     return Promise.resolve(true);
  //   } catch (e) {
  //     console.error('OAuth refresh rejected', e);
  //     this.setUserSubject(null);
  //     return Promise.resolve(false);
  //   }
  // }

  public async logout(): Promise<void> {
    // Logout/revoking tokens currently doesn't seem to be implemented in @openid/appauth
    // See: https://github.com/openid/AppAuth-JS/issues/52
    // So maybe just clear everything for now?
    try {
      this._appBusService.logoutRequested();
      this.oidcConfig = undefined;
    } catch (e) {
      console.error('On Logout', e);
    } finally {
      await this._storage.clear();

      this.accessToken = null;
      this.idToken = null;
      this.refreshToken = null;
      this.expiresIn = null;
      this.issuedAt = null;
      this.currentTenant = null;

      await this.setUserSubject(null);
      this._appBusService.inTakePassed(null);
      this._currentProfile = null;

      this._appBusService.logout();

      await this._router.navigate(this.applicationPaths.LoginPathComponents);

      await Promise.resolve();
    }
  }

  public async getProfiles(): Promise<AthleteDto[]> {
    try {
      const availableProfiles = await this.profileClient
        .getProfiles()
        .toPromise();
      return availableProfiles;
    } catch {
      return null;
    }
  }

  public async fetchUserInfo(): Promise<UserEntity> {
    console.debug('AuthorizeService => fetchUserInfo');
    const userInfo = await this.angularRequestor.xhr<any>({
      url: this.oidcConfig.resourceUrl,
      dataType: 'application/json',
      headers: new HttpHeaders({
        Authorization: this.getAuthorizationHeader(),
      }),
    });

    const userInfoObject: UserEntity = this.mapUserEntity(JSON.parse(userInfo));

    await this._storage.set({
      key: applicationStorageFields.STORAGE_USER_INFO,
      value: JSON.stringify(userInfoObject),
    });

    return Promise.resolve(userInfoObject);
  }

  public getAuthorizationHeader(): string {
    console.debug('AuthorizeService => getAuthorizationHeader');
    // TODO: maybe validate the access token is set and valid
    return `Bearer ${this.accessToken}`;
  }

  public getBaseUrl(): string {
    return this.baseUrl;
  }

  private mapUserEntity(userObject: any): UserEntity {
    const userEntity: UserEntity = new UserEntity();
    userEntity.name = userObject.name;
    userEntity.sub = userObject.sub;
    userEntity.email = userObject.email;
    userEntity.email_verified = userObject.email_verified;
    userEntity.preferred_username = userObject.preferred_username;
    userEntity.profiles = userObject.profiles;
    userEntity.tenants = userObject.tenants;
    userEntity.isInTakePassed = userObject.isInTakePassed;
    userEntity.dateOfBirth = userObject.dateOfBirth;
    userEntity.firstName = userObject.firstName;
    userEntity.lastName = userObject.lastName;
    userEntity.zip = userObject.zip;
    userEntity.currentProfileId = userObject.currentProfileId;
    userEntity.mobilePhone = userObject.mobilePhone;
    return userEntity;
  }

  private validateAccessToken(accessToken: string): void {
    if (!accessToken || accessToken.trim() === '') {
      throw new Error('Failed to validate access token.');
    }

    const tokenData = this.jwtHelperService.decodeToken(accessToken);
    if (!tokenData) {
      throw new Error('Failed to validate access token.');
    }
    this.userRegistrationNotCompleted = !!tokenData.regNotCompleted;
    this._storage.set({
      key: applicationStorageFields.STORAGE_USER_NOT_COMPLETED,
      value: `${this.userRegistrationNotCompleted}`,
    });
  }

  private async repopulateFromStorage(): Promise<void> {
    console.debug('AuthorizeService => repopulateFromStorage');

    const serializedUserInfo = await this._storage.get({
      key: applicationStorageFields.STORAGE_USER_INFO,
    });

    const serializedOidcConfig = await this._storage.get({
      key: applicationStorageFields.STORAGE_OIDC_CONFIG,
    });
    const accessToken = await this._storage.get({
      key: applicationStorageFields.STORAGE_ACCESS_TOKEN,
    });
    const refreshToken = await this._storage.get({
      key: applicationStorageFields.STORAGE_REFRESH_TOKEN,
    });
    const idToken = await this._storage.get({
      key: applicationStorageFields.STORAGE_ID_TOKEN,
    });
    const expiresIn = await this._storage.get({
      key: applicationStorageFields.STORAGE_EXPIRES_IN,
    });
    const issuedAt = await this._storage.get({
      key: applicationStorageFields.STORAGE_ISSUED_AT,
    });

    // const nonce = await this._storage.get({
    //   key: applicationStorageFields.STORAGE_NONCE,
    // });
    //
    // const codeChallenge = await this._storage.get({
    //   key: applicationStorageFields.STORAGE_PKCE_CHALLENGE,
    // });
    // const codeMethod = await this._storage.get({
    //   key: applicationStorageFields.STORAGE_PKCE_METHOD,
    // });
    // const codeVerifier = await this._storage.get({
    //   key: applicationStorageFields.STORAGE_PKCE_VERIFIER,
    // });
    const userRegistrationNotCompleted = await this._storage.get({
      key: applicationStorageFields.STORAGE_USER_NOT_COMPLETED,
    });

    if (serializedOidcConfig.value) {
      console.debug(
        'AuthorizeService => repopulateFromStorage => oidc config',
        accessToken.value,
      );
      this.oidcConfig = JSON.parse(serializedOidcConfig.value);
    }

    if (serializedUserInfo.value) {
      console.debug(
        'AuthorizeService => repopulateFromStorage => oidc config',
        accessToken.value,
      );
      const user: UserEntity = JSON.parse(serializedUserInfo.value);

      await this.setUserSubject(user);
    } else {
      await this.setUserSubject(null);
    }

    if (accessToken.value) {
      console.debug(
        'AuthorizeService => repopulateFromStorage => access token',
        accessToken.value,
      );
      this.accessToken = accessToken.value;
    }

    if (refreshToken.value) {
      console.debug(
        'AuthorizeService => repopulateFromStorage => refresh token',
        refreshToken.value,
      );
      this.refreshToken = refreshToken.value;
    }

    if (idToken.value) {
      console.debug(
        'AuthorizeService => repopulateFromStorage => id token',
        idToken.value,
      );
      this.idToken = idToken.value;
    }

    if (expiresIn.value) {
      console.debug(
        'AuthorizeService => repopulateFromStorage => expires in',
        expiresIn.value,
      );
      this.expiresIn = parseInt(expiresIn.value, 10);
    }

    if (issuedAt.value) {
      console.debug(
        'AuthorizeService => repopulateFromStorage => issued at',
        issuedAt.value,
      );
      this.issuedAt = parseInt(issuedAt.value, 10);
    }

    if (userRegistrationNotCompleted && userRegistrationNotCompleted.value) {
      try {
        this.userRegistrationNotCompleted = JSON.parse(
          userRegistrationNotCompleted.value,
        );
      } catch {
        this.userRegistrationNotCompleted = false;
      }
    }
    return Promise.resolve();
  }

  // private generateRefreshOptions(
  //   oidcOptions: OAuth2AuthenticateOptions,
  // ): OAuth2RefreshTokenOptions {
  //   return {
  //     appId: oidcOptions.appId,
  //     accessTokenEndpoint: oidcOptions.accessTokenEndpoint,
  //     refreshToken: this.refreshToken,
  //     scope: oidcOptions.scope,
  //   };
  // }

  private requestAccessToken(
    username: string,
    password: string,
    options: OAuth2AuthenticateOptions,
  ): Observable<any> {
    let url_ = this.baseUrl + '/connect/token';
    url_ = url_.replace(/[?&]$/, '');

    const body = new HttpParams()
      .set('username', username)
      .set('password', password)
      .set('scope', options.scope)
      .set('client_id', options.appId)
      .set('grant_type', 'password');

    const options_: any = {
      body: body.toString(),
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'content-type': 'application/x-www-form-urlencoded',
      }),
    };

    return this.http
      .request('post', url_, options_)
      .pipe(
        _observableMergeMap((response_: any) => {
          return this.processRequestToken(response_);
        }),
      )
      .pipe(
        _observableCatch((response_: any) => {
          if (response_ instanceof HttpResponseBase) {
            try {
              return this.processRequestToken(<any>response_);
            } catch (e) {
              return <Observable<any>>(<any>_observableThrow(e));
            }
          } else return <Observable<any>>(<any>_observableThrow(response_));
        }),
      );
  }

  private refreshAccessToken(
    refreshToken: string,
    options: OAuth2AuthenticateOptions,
  ): Observable<any> {
    let url_ = this.baseUrl + '/connect/token';
    url_ = url_.replace(/[?&]$/, '');

    const body = new HttpParams()
      .set('refresh_token', refreshToken)
      .set('scope', options.scope)
      .set('client_id', options.appId)
      .set('grant_type', 'refresh_token');

    const options_: any = {
      body: body.toString(),
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'content-type': 'application/x-www-form-urlencoded',
      }),
    };

    return this.http
      .request('post', url_, options_)
      .pipe(
        _observableMergeMap((response_: any) => {
          return this.processRequestToken(response_);
        }),
      )
      .pipe(
        _observableCatch((response_: any) => {
          if (response_ instanceof HttpResponseBase) {
            try {
              return this.processRequestToken(<any>response_);
            } catch (e) {
              return <Observable<any>>(<any>_observableThrow(e));
            }
          } else return <Observable<any>>(<any>_observableThrow(response_));
        }),
      );
  }

  protected processRequestToken(response: HttpResponseBase): Observable<any> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse
        ? response.body
        : (<any>response).error instanceof Blob
        ? (<any>response).error
        : undefined;

    const _headers: any = {};
    if (response.headers) {
      for (const key of response.headers.keys()) {
        _headers[key] = response.headers.get(key);
      }
    }
    if (status === 200) {
      return this.blobToText(responseBlob).pipe(
        _observableMergeMap((_responseText) => {
          return _observableOf<any>(JSON.parse(_responseText));
        }),
      );
    } else if (status !== 200 && status !== 204) {
      return this.blobToText(responseBlob).pipe(
        _observableMergeMap((_responseText) => {
          return this.throwException(
            'An unexpected server error occurred.',
            status,
            _responseText,
            _headers,
          );
        }),
      );
    }
    return _observableOf<any>(<any>null);
  }

  private throwException(
    message: string,
    status: number,
    response: string,
    headers: { [key: string]: any },
    result?: any,
  ): Observable<any> {
    if (result !== null && result !== undefined)
      return _observableThrow(result);
    else return _observableThrow({ message, status, response, headers });
  }

  private blobToText(blob: any): Observable<string> {
    return new Observable<string>((observer: any) => {
      if (!blob) {
        observer.next('');
        observer.complete();
      } else {
        const reader = new FileReader();
        reader.onload = (event): void => {
          observer.next((<any>event.target).result);
          observer.complete();
        };
        reader.readAsText(blob);
      }
    });
  }

  //   private async generateNonce(): Promise<void> {
  //     // Generate and store the nonce as per
  //     // https://auth0.com/docs/api-auth/tutorials/nonce
  //     const crypto = new DefaultCrypto();
  //     const nonce = await crypto.generateRandom(128);
  //     console.debug('startAuth => generateNonce => nonce', nonce);
  //     await this._storage.set({ key: this.STORAGE_NONCE, value: nonce });
  //     this.nonce = nonce;
  //   }

  //   private async generatePKCE(): Promise<void> {
  //     const crypto = new DefaultCrypto();
  //     const method = 'S256';
  //     const codeVerifier = await crypto.generateRandom(128);
  //     const challenge = await crypto.deriveChallenge(codeVerifier);

  //     console.debug('startAuth => generatePKCE', codeVerifier, challenge, method);

  //     await this._storage.set({ key: this.STORAGE_PKCE_CHALLENGE, value: challenge });
  //     this.codeChallenge = challenge;

  //     await this._storage.set({ key: this.STORAGE_PKCE_METHOD, value: method });
  //     this.codeMethod = method;

  //     await this._storage.set({ key: this.STORAGE_PKCE_VERIFIER, value: codeVerifier });
  //     this.codeVerifier = codeVerifier;
  //   }

  //   private async fetchAuthConfig(): Promise<void> {
  //     console.debug('fetchAuthConfig => started', this.config.oidcServerUrl);

  //     this.authConfig = await AuthorizationServiceConfiguration.fetchFromIssuer(
  //       this.config.oidcServerUrl,
  //       this.angularRequestor,
  //     );

  //     console.debug('fetchAuthConfig => end', this.authConfig);
  //   }

  //   private populateUserInfo(accessToken: string): void {
  //     const user = this.jwtHelperService.decodeToken(accessToken);
  //     this.setUserSubject(user);
  // }

  //   private async setupRedirectListener(): Promise<void> {
  //     App.addListener('appUrlOpen', async (data) => {
  //       console.debug('startAuth => appUrlOpen => data', data);

  //       if (data.url.indexOf(this.config.redirectUri) !== -1) {
  //         console.debug(
  //           'startAuth => appUrlOpen => oidc redirect, closing browser',
  //         );
  //         await Browser.close();

  //         const util = new BasicQueryStringUtils();
  //         const values = util.parseQueryString(
  //           data.url.replace(this.config.redirectUri + '#', ''),
  //         );

  //         const authorizationCode = values['code'];
  //         const idToken = values['id_token'];

  //         await this._storage.set({
  //           key: this.STORAGE_AUTHORIZATION_CODE,
  //           value: authorizationCode,
  //         });
  //         this.authorizationCode = authorizationCode;

  //         await this._storage.set({ key: this.STORAGE_ID_TOKEN, value: idToken });
  //         this.idToken = idToken;
  //         this.setUserSubject(this.getUserInfo());

  //         await this.fetchTokensForAuthorizationCode();
  //       }
  //     });

  //     // TODO: handle the user cancelling the auth process
  //     // Browser.addListener('browserFinished', async (info) => {
  //     //     console.debug('startAuth => Browser => browserFinished', info);
  //     //     // the user maybe clicked done before finishing?
  //     // });
  //   }

  //   private async fetchTokensForAuthorizationCode(): Promise<void> {
  //     console.debug('fetchTokensForAuthorizationCode => started');

  //     const handler = new BaseTokenRequestHandler(this.angularRequestor);

  //     const request = new TokenRequest({
  //       client_id: this.config.clientId,
  //       redirect_uri: this.config.redirectUri,
  //       grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
  //       code: this.authorizationCode,
  //       extras: {
  //         code_verifier: this.codeVerifier,
  //       },
  //     });

  //     const tokenRequestResult = await handler.performTokenRequest(
  //       this.authConfig,
  //       request,
  //     );

  //     const {
  //       accessToken,
  //       refreshToken,
  //       idToken,
  //       expiresIn,
  //       issuedAt,
  //     } = tokenRequestResult;

  //     console.debug(
  //       'fetchTokensForAuthorizationCode => access token',
  //       accessToken,
  //     );
  //     console.debug(
  //       'fetchTokensForAuthorizationCode => refresh token',
  //       refreshToken,
  //     );
  //     console.debug('fetchTokensForAuthorizationCode => id token', idToken);
  //     console.debug('fetchTokensForAuthorizationCode => expires in', expiresIn);
  //     console.debug('fetchTokensForAuthorizationCode => issued at', issuedAt);

  //     await this._storage.set({ key: this.STORAGE_ACCESS_TOKEN, value: accessToken });
  //     this.accessToken = accessToken;

  //     await this._storage.set({ key: this.STORAGE_REFRESH_TOKEN, value: refreshToken });
  //     this.refreshToken = refreshToken;

  //     await this._storage.set({ key: this.STORAGE_ID_TOKEN, value: idToken });
  //     this.idToken = idToken;
  //     this.setUserSubject(this.getUserInfo());

  //     await this._storage.set({
  //       key: this.STORAGE_EXPIRES_IN,
  //       value: expiresIn.toString(),
  //     });
  //     this.expiresIn = expiresIn;

  //     await this._storage.set({
  //       key: this.STORAGE_ISSUED_AT,
  //       value: issuedAt.toString(),
  //     });
  //     this.issuedAt = issuedAt;
  //   }
}

export class AuthException extends Error {
  message: string;
  status: number;
  error: string;
  errorDescription: string;
  headers: { [key: string]: any };
  result: any;

  constructor(
    message: string,
    status: number,
    error: string,
    errorDescription: string,
    headers: { [key: string]: any },
  ) {
    super();

    this.message = message;
    this.status = status;
    this.error = error;
    this.errorDescription = errorDescription;
    this.headers = headers;
  }

  protected isAuthException = true;

  static isAuthException(obj: AuthException): obj is AuthException {
    return obj.isAuthException === true;
  }
}
