import { Type } from '@angular/core';
import { PlatformService } from './services/platform.service';
import { SocialLoginService } from '@common/co/core/services/social-login.service';
import {
  APP_INITIALIZER,
  ErrorHandler,
  ModuleWithProviders,
  NgModule,
} from '@angular/core';
import { MaterialModule } from './material.module';
import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  HttpClientXsrfModule,
} from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoggingService } from '@common/services/logging.service';
import { ErrorHandlerService } from '@common/co/core/services/error-handler.service';
import { NotificationComponent } from '@common/co/core/components/notification/notification.component';
import { SplCountdownComponent } from '@common/co/core/components/spl-countdown/spl-countdown.component';
import { FuseShortcutsModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { RouteInterceptorService } from '@common/co/core/services/route-interceptor.service';
import { AppStateService } from '@common/co/core/services/app-state.service';
import { PushNotificationsService } from '@common/co/core/services/push-notifications.service';
import { PUSH_NOTIFICATION_RESOLVER } from '@common/co/core/resolvers/defaultPushNotification.resolver';
import { ShowValidationInfoDirective } from '@common/co/core/directives/showValidationInfo.directive';
import { OnlyIntNumbersDirective } from '@common/co/core/directives/onlyIntNumbers.directive';
import { OnlyFloatNumbersDirective } from '@common/co/core/directives/onlyFloatNumbers.directive';
import { SplAppApiService } from '@common/co/core/services/splAppApi.service';
import { SplCountdownManagementService } from '@common/co/core/components/spl-countdown/spl-countdown.management-service';
import { FullDateFormatPipe } from '@common/shared/pipes/fullDateFormat.pipe';
import { DisplayOnlyOnMobileDirective } from '@common/co/core/directives/displayOnlyOnMobile.directive';
import { DisplayOnlyOnDesktopDirective } from '@common/co/core/directives/displayOnlyOnDesktop.directive';
import { SplSplashScreenService } from './services/splash-screen.service';
import { AppBusService } from '@common/co/core/services/app-bus.service';
import { NotificationService } from '@common/co/core/services/notification.service';
import { TimeZoneInterceptor } from '@common/interceptors/time-zone.interceptor';
import { StatisticsService } from '@common/services/statistics.service';
import { LanguageService } from '@common/i18n/language.service';

@NgModule({
  declarations: [
    NotificationComponent,
    SplCountdownComponent,
    ShowValidationInfoDirective,
    OnlyIntNumbersDirective,
    OnlyFloatNumbersDirective,
    FullDateFormatPipe,
    DisplayOnlyOnMobileDirective,
    DisplayOnlyOnDesktopDirective,
  ],
  imports: [
    HttpClientModule,
    HttpClientXsrfModule,
    RouterModule,
    MaterialModule,
    FuseShortcutsModule,
    FuseSharedModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    SplCountdownComponent,
    FuseShortcutsModule,
    FuseSharedModule,
    ShowValidationInfoDirective,
    FullDateFormatPipe,
    OnlyIntNumbersDirective,
    OnlyFloatNumbersDirective,
    DisplayOnlyOnMobileDirective,
    DisplayOnlyOnDesktopDirective,
  ],
  providers: [
    PlatformService,
    SocialLoginService,
    LoggingService,
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    RouteInterceptorService,
    PushNotificationsService,
    SplAppApiService,
    SplCountdownManagementService,
    SplSplashScreenService,
    LanguageService,
    {
      provide: APP_INITIALIZER,
      useFactory: initSplashScreenService,
      deps: [SplSplashScreenService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initRouteInterceptor,
      deps: [RouteInterceptorService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initPushNotifications,
      deps: [PushNotificationsService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initSplAppApiService,
      deps: [SplAppApiService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TimeZoneInterceptor,
      multi: true,
    },
  ],
})
export class SplCoreModule {
  public static forRoot(
    pushNotificationResolver: Type<any>,
  ): ModuleWithProviders<SplCoreModule> {
    console.log('root');
    return {
      ngModule: SplCoreModule,
      providers: [
        AppBusService,
        NotificationService,
        AppStateService,
        StatisticsService,
        {
          provide: PUSH_NOTIFICATION_RESOLVER,
          useClass: pushNotificationResolver,
        },
        {
          provide: APP_INITIALIZER,
          useFactory: initStatisticService,
          deps: [StatisticsService],
          multi: true,
        },
      ],
    };
  }
}

export function initSplashScreenService(
  splSplashScreenService: SplSplashScreenService,
) {
  return (): void => splSplashScreenService.init();
}

export function initRouteInterceptor(
  routeInterceptorService: RouteInterceptorService,
) {
  return (): void => routeInterceptorService.initialize();
}

export function initPushNotifications(
  pushNotificationsService: PushNotificationsService,
) {
  return (): Promise<void> => pushNotificationsService.initialize();
}

export function initSplAppApiService(splAppApiService: SplAppApiService) {
  return (): void => splAppApiService.initialize();
}

export function initStatisticService(statisticsService: StatisticsService) {
  return (): void => statisticsService.initialize();
}
