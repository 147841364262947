import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'star-rating',
  templateUrl: 'star-rating.component.html',
  styleUrls: ['star-rating.component.scss'],
})
export class StarRatingComponent implements OnInit {
  private _itemsCount: number = 5;

  @Input('itemsCount')
  public get itemsCount(): number {
    return this._itemsCount;
  }
  public set itemsCount(value: number) {
    this._itemsCount = value;
  }

  private _precision: StarRatingPresision = StarRatingPresision.item;

  @Input('precision')
  public get precision(): StarRatingPresision {
    return this._precision;
  }
  public set precision(value: StarRatingPresision) {
    this._precision = value;
  }

  private _value: number = 0;

  @Input('value')
  public get value(): number {
    return this._value;
  }
  public set value(value: number) {
    if (value !== this._value) {
      this._value = value;
      this.buildRating();
    }
  }

  @Output()
  public valueChange = new EventEmitter<number>();

  public ratingArr = [];

  constructor(public changeDetector: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.buildRating();
  }

  buildRating(): void {
    const ratingArr = [];
    for (let index = 0; index < this.itemsCount; index++) {
      ratingArr.push(index);
    }
    this.ratingArr = ratingArr;
    this.changeDetector.markForCheck();
    this.changeDetector.detectChanges();
  }

  onClick(e: MouseEvent, rating: number): void {
    if (this.precision == StarRatingPresision.half) {
      const trgt: Element = e.target as Element;
      const eltWidth = trgt.getBoundingClientRect().width;
      const halfEltWidth = eltWidth / 2;
      const mouseXPos = e.offsetX;
      console.log('rating:', rating);
      if (mouseXPos <= halfEltWidth) {
        console.log('Mouse on left side');
        this.value = rating - 0.5;
      } else {
        console.log('Mouse on right side');
        this.value = rating;
      }
      console.log('new rating:', this.value);
    } else {
      this.value = rating;
    }

    this.valueChange.emit(this._value);
  }

  getColor(index: number): StarRatingColor {
    if (this.value === undefined || this.value === null) {
      return StarRatingColor.empty;
    }
    let styleClass: StarRatingColor = StarRatingColor.empty;
    if (index > Math.ceil(this.value)) {
      styleClass = StarRatingColor.empty;
    } else {
      styleClass = StarRatingColor.full;
    }
    return styleClass;
  }

  showIcon(index: number): StarRatingIcon {
    if (this.value === undefined || this.value === null) {
      return StarRatingIcon.star;
    }

    if (index <= Math.ceil(this.value) && index > Math.floor(this.value)) {
      return StarRatingIcon.half;
    }
    return StarRatingIcon.star;
  }
}

export enum StarRatingPresision {
  half = 'half',
  item = 'item',
}

export enum StarRatingIcon {
  star = 'star',
  half = 'star_half',
}

export enum StarRatingColor {
  empty = 'empty-star',
  full = 'full-star',
}
